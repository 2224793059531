<template>
  <div class="dates-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div v-if="reason === 'urlaubbis' || reason === 'krankbis'" class="col-12">
            <input-date v-model="local" :vld="v$.local" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import inputDate from '@/components/form-elements/Date'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { requiredIf } from '@vuelidate/validators'

export default {
  name: 'ReasonNoTemeperature',
  components: {
    inputDate
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  props: {
    label: {
      default: 'Text',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    reason: String,
    modelValue: Date
  },
  validations () {
    return {
      local: {
        required: requiredIf(function () {
          return this.reason === 'krankbis' || this.reason === 'urlaubbis'
        })
      }
    }
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>
<style lang="scss">
.reason-wrapper{
  .form-check{
    margin-top: 15px;
  }
}
</style>
