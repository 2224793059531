<template>
  <CRow>
    <CCol :sm="12" class="temp-form" v-if="user && ((user.roles.includes('ROLE_SUPERUSER') || user.roles.includes('ROLE_ADMIN')) || user.boxNo !== null)">
          <h5 v-if="benutzer">
            <CIcon size="xl" name="cil-calendar"/>
            DATEN ERFASSEN - <em>Benutzer {{benutzer.name}}</em>
          </h5>
          <h5 v-else>
            <CIcon size="xl" name="cil-calendar"/>
            DATEN ERFASSEN
          </h5>
          <CForm @submit.prevent="sendData()" autocomplete="off">
            <CRow>
              <CCol class="col-12 col-md-4">
                <input-benutzer v-model="form.benutzer" :vld="v$.form.benutzer" label="Benutzer:*"/>
              </CCol>
              <CCol class="col-12 col-md-2">
                <input-date-restricted v-model="form.date" :min-date="minDate" :max-date="maxDate" :vld="v$.form.date" showLabel="1" label="Datum:*"/>
              </CCol>
              <CCol class="col-12 col-md-2">
                <input-time v-model="form.time" :vld="v$.form.time" label="Zeit:*"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="col-12 col-md-4">
                <input-temperature v-if="!noData" v-model="form.temperature" :vld="v$.form.temperature" label="Temperatur (°C):"/>
              </CCol>
              <CCol class="col-12 col-md-4">
                <CFormCheck
                  v-model="noData"
                  id="input-reasonNoTemp"
                  label="Heute wird keine Temperatur übermittelt, da:"
                />
                <br/>
                <div class="row px-5">
                  <input-reason-no-temperature v-model="form.reason" :vld="v$.form.reason"/>
                </div>

              </CCol>
              <CCol class="col-12 col-md-3">
                <CRow>
                  <br class="d-none d-md-block"/>
                  <br class="d-none d-md-block"/>
                  <br class="d-none d-md-block"/>
                  <br class="d-none d-md-block"/>
                  <br class="d-none d-md-block"/>
                </CRow>
                <CRow>
                  <input-dates-no-temperature v-model="form.datereason" :vld="v$.form.datereason" :reason="form.reason"/>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <hr/>
              <div class="px-md-5">
                <input-submit class="" label="Abschicken" :vld="v$.form"/>
              </div>
              <FormError v-if="formError" :errormessage="formError"/>
            </CRow>

          </CForm>
    </CCol>
    <CCol :sm="12" class="temp-form" v-else>
      <div class="alert alert-danger">
        Sie können keine Temperatur erfassen ! <br/>
        <br/>
        Bei Ihnen ist noch keine Kühlbox-Nummer hinterlegt.  <br/>
        Bitte melden Sie sich bei Ihrem Ansprechpartner !
      </div>
    </CCol>
  </CRow>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { required, requiredIf } from '@vuelidate/validators'
import FormError from '@/components/form-elements/Error'
import inputDateRestricted from '@/components/form-elements/DateRestricted'
import inputTime from '@/components/form-elements/Time'
import inputTemperature from '@/components/form-elements/Temperature'
import inputSubmit from '@/components/form-elements/Submit'
import inputReasonNoTemperature from '@/components/form-elements/ReasonNoTemperature'
import inputDatesNoTemperature from '@/components/form-elements/DatesNoTemperature'
import inputBenutzer from '@/components/form-elements/Benutzer'
import Moment from 'moment'

export default {
  name: 'TemperaturForm',
  components: {
    inputTemperature,
    inputDateRestricted,
    inputTime,
    inputSubmit,
    inputReasonNoTemperature,
    inputDatesNoTemperature,
    inputBenutzer,
    FormError
  },
  props: {
    text: {
      default: 'Keine Daten für die Anzeige.',
      type: String
    }
  },
  setup () {
    const maxDate = Moment().format('YYYY-MM-DD')
    const minDate = Moment().subtract('2', 'days').format('YYYY-MM-DD')

    return {
      maxDate,
      minDate,
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  watch: {
    'form.reason': function () {
      if (this.form.reason != null) {
        this.noData = true
      } else {
        this.noData = false
      }
    },
    noData: function () {
      if (!this.noData) {
        this.form.reason = null
      } else {
      }
    }
  },
  validations () {
    return {
      form: {
        benutzer: {
          required
        },
        date: {
          required
        },
        datereason: {
          required: requiredIf(function () {
            return this.form.reason === 'krankbis' || this.form.reason === 'urlaubbis'
          })
        },
        time: {
          required
        },
        temperature: {
          required: requiredIf(function () {
            return this.form.reason === ''
          })
        },
        reason: {

        }
      }
    }
  },
  data () {
    return {
      formError: null,
      noData: false,
      benutzer: null,
      form: {
        benutzer: '',
        date: new Moment().format('Y-MM-DD'),
        time: new Moment().format('HH:mm'),
        temperature: '',
        reason: '',
        datereason: null
      }
    }
  },
  mounted () {
    // this.loadMitarbeiter()
  },
  methods: {
    sendData () {
      this.axios.post('/daten/post', {
        user: this.form.benutzer,
        date: Moment(this.form.date + ' ' + this.form.time).format('YYYY-MM-DD hh:mm'),
        temperature: this.form.temperature,
        noData: this.form.reason ? this.form.reason : undefined,
        vacationSickDate: this.form.datereason ? Moment(this.form.datereason + ' ' + '21:59').format('YYYY-MM-DD HH:mm') : undefined
      }).then(() => {
        this.toast.success('Die Nutzerdaten wurde geändert.')
        this.form = {
          benutzer: '',
          date: new Moment().format('Y-MM-DD'),
          time: new Moment().format('HH:mm'),
          temperature: '',
          reason: '',
          datereason: ''
        }
        this.noData = false
        this.$router.push({ path: '/daten/neu' })
      }).catch((error) => {
        this.formError = error.response.data
      })
    },
    loadMitarbeiter () {
      if (this.uuid) {
        this.axios.get('/user/get/user/' + this.uuid)
          .then((response) => {
            this.form.benutzer = response.data[0]
          })
      }
    }
  }
}
</script>

<style>
.temp-form h5{
  font-size: 2rem;
  font-weight: bold;
  color: #2C5082;
  text-transform: uppercase;
}
</style>
