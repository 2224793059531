<template>
  <div class="reason-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="row">
        <input class="form-check-input d-none" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp0" value="" label="Test"/>
          <label class="d-none form-check-label">Test</label>
        <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp1" value="home"/>
          &nbsp;<label class="form-check-label d-inline">Home Office</label>
        </div>
        <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp2" value="tagung" label="Tagung"/>
          &nbsp;<label class="form-check-label d-inline">Tagung</label>
        </div>
        <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp3" value="feiertag" label="Feiertag"/>
          &nbsp;<label class="form-check-label d-inline">Feiertag</label>
        </div>
        <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp4" value="keine-muster" label="Keine Mitnahme von Mustern"/>
          &nbsp;<label class="form-check-label d-inline">Keine Mitnahme von Mustern</label>
      </div>
      <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp5" value="urlaubbis" label="Urlaub bis einschließlich"/>
        &nbsp;<label class="form-check-label d-inline">Urlaub bis einschließlich</label>
      </div>
        <div class="col-12">
          <input class="form-check-input" type="radio" v-model="local" name="reasonNoTemp" id="reasonNoTemp6" value="krankbis" label="Krank bis einschließlich"/>
          &nbsp;<label class="form-check-label d-inline">Krank bis einschließlich</label>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'

export default {
  name: 'ReasonNoTemeperature',
  components: {
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  props: {
    label: {
      default: 'Text',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: String
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>
<style lang="scss">
 .reason-wrapper{
   .form-check{
     margin-top: 15px;
   }
 }
</style>
