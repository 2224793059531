<template>
  <CFormLabel for="input-temperature" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="my-3 formelements-text">
    <CFormInput
      id="input-temperature"
      step="1"
      min="0"
      max="40"
      type="number"
      :invalid="vld.$invalid"
      :valid="vld.$dirty"
      v-model="local"
      @blur="vld.$commit"
    />
    <CFormRange id="customRange1" class="my-2" label="Test" :min="0" :max="40" :step="1" v-model="local"/>
  </CInputGroup>
  <CAlert color="danger" ref="alertDiv" class="m-auto my-1 alert alert-danger mb-3" v-if="alertTest">
    ACHTUNG, Temperatur ist außerhalb des Normalbereiches!
  </CAlert>
</template>

<script>
export default {
  name: 'formelements-temperature',
  props: {
    label: {
      default: 'Text',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    max: {
      default: 40,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    maxAlert: {
      default: 26,
      type: Number
    },
    minAlert: {
      default: 14,
      type: Number
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: String
  },
  data () {
    return {
      alert: {
        default: false,
        type: Boolean
      }
    }
  },
  computed: {
    alertTest: {
      get () {
        return this.alert
      }
    },
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  watch: {
    modelValue: function (val) {
      if (val > this.maxAlert || val < this.minAlert) {
        this.alert = true
        console.log('!!! model value changed ', val)
        console.log('!!! model value changed ', this.alert)
        return
      }
      this.alert = false
      console.log('!!! model value changed ', val)
      console.log('!!! model value changed ', this.alert)
    }
  }
}
</script>

<style lang="scss">

 #customRange1 {
   background: transparent;
   &::-webkit-slider-runnable-track {
      height: 2rem;
      background: #EEE;
      background: -moz-linear-gradient(left, rgba(39,87,230,1) 0%, rgba(231,56,39,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(39,87,230,1)), color-stop(100%, rgba(231,56,39,1)));
      background: -webkit-linear-gradient(left, rgba(39,87,230,1) 0%, rgba(231,56,39,1) 100%);
      background: -o-linear-gradient(left, rgba(39,87,230,1) 0%, rgba(231,56,39,1) 100%);
      background: -ms-linear-gradient(left, rgba(39,87,230,1) 0%, rgba(231,56,39,1) 100%);
      background: linear-gradient(to right, rgba(39,87,230,1) 0%, rgba(231,56,39,1) 100%);
    }
}
</style>
