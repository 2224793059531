<template>
  <CFormLabel for="input-boxNo" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="mb-3 formelements-text">
    <CFormInput
      id="input-date"
      :invalid="vld.$invalid"
      :valid="vld.$dirty"
      v-model="local"
      @blur="vld.$commit"
      type="date"
    />
    <CFormFeedback invalid v-if="vld.$invalid">
      Pflichtfeld, bitte tätigen Sie eine Eingabe.
    </CFormFeedback>
  </CInputGroup>
</template>

<script>

import Moment from 'moment'

export default {
  name: 'Date',
  props: {
    label: {
      default: '',
      type: String
    },
    showLabel: {
      default: false,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: function () { return new Moment().format('YYYY-MM-DD') },
      required: true
    }
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  setup () {
  },
  mounted () {
    console.log(this.modelValue)
  },
  methods: {
    getDate () {
      return new Moment().format('YYYY-MM-DD')
    },
    getTime () {
      return new Moment().format('HH:mm')
    }
  }
}
</script>
