<template>
  <CFormLabel for="input-benutzer" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="mb-3 formelements-text">
    <CFormSelect
          aria-label="Default select example"
          :options="getOptions()"
          :invalid="vld.$invalid"
          :valid="vld.$dirty"
          v-model="local"
          @blur="vld.$commit"
    >
    </CFormSelect>

    <CFormFeedback invalid v-if="vld.$invalid">
      Pflichtfeld, bitte tätigen Sie eine Eingabe.
    </CFormFeedback>
  </CInputGroup>
</template>

<script>

export default {
  name: 'Benutzer',
  props: {
    label: {
      default: 'Benutzer:*',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: function () { return null },
      required: true
    }
  },
  data () {
    return {
      online: [],
      deleted: []
    }
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    user () {
      return this.$store.getters['security/user']
    }
  },
  mounted () {
    this.local = this.user.userid
    this.loadBenutzer()
  },
  methods: {
    getOptions () {
      if (this.user.roles.includes('ROLE_USER')) {
        return [
          {
            label: '[' + this.user.boxNo + '] ' + this.user.name,
            value: this.user.userid
          }
        ]
      } else {
        const benutzerOptions = []

        this.online.forEach(curuser => {
          if (curuser.boxNo) {
            benutzerOptions.push({
              label: '[' + curuser.boxNo + '] ' + curuser.name,
              value: curuser.mitarbeiterid
            })
          }
        })

        return benutzerOptions
      }
    },
    loadBenutzer () {
      this.axios.get('/user/get')
        .then((response) => {
          this.online = response.data
        })
      this.axios.get('/user/get/deleted')
        .then((response) => {
          this.deleted = response.data
        })
    }
  }
}
</script>
